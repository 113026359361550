$(function () {

    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') }
    });

    function nl2br (str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    //$('#search-hideseek').hideseek();

    $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
      icons: {
          time: 'fal fa-clock',
          date: 'fal fa-calendar',
          up: 'fal fa-arrow-up',
          down: 'fal fa-arrow-down',
          previous: 'fal fa-chevron-left',
          next: 'fal fa-chevron-right',
          today: 'fal fa-calendar-check-o',
          clear: 'fal fa-trash',
          close: 'fal fa-times'
      }
    });

    $('.selectpicker').selectpicker({
        showSubtext : true
    });

    $('.datetimepicker').datetimepicker({
        format: 'DD/MM/YYYY',
        locale: 'fr'
    });

    $('.datetimer').datetimepicker({
        format: 'LT',
        locale: 'fr'
    });

    $('[rel="tooltip"]').tooltip();

    $('.ui-sortable, .liste_triable').sortable();

    $(".radio-switch").bootstrapSwitch({
        size : 'small',
    });

    var datatable = $('.datatable').DataTable( {
        "info":     false,
        "order": [[ 0, "asc" ]],
        "columnDefs": [{ "width": '20%', "targets": 0 }],
        language: {
            processing:     "Traitement en cours...",
            search:         "Rechercher :",
            lengthMenu:     "Afficher _MENU_ éléments",
            info:           "Affichage de l'élément _START_ au _END_ sur _TOTAL_ éléments",
            infoEmpty:      "Affichage de l'élément 0 Ã  0 sur 0 éléments",
            infoFiltered:   "(filtré de _MAX_ éléments au total)",
            infoPostFix:    "",
            loadingRecords: "Chargement en cours...",
            zeroRecords:    "Aucun élément à afficher",
            emptyTable:     "Aucune donnée disponible dans le tableau",
            paginate: {
                first:      "Premier",
                previous:   "Précédent",
                next:       "Suivant",
                last:       "Dernier"
            },
            aria: {
                sortAscending:  ": activer pour trier la colonne par ordre croissant",
                sortDescending: ": activer pour trier la colonne par ordre décroissant"
            }
        },
        "aoColumnDefs": [
          { "bSearchable": true, "aTargets": [ 0,1 ] },
          { "orderable": false, "targets": -1 }
        ]
    });

    if ($('.liste_triable').length) {
        $(".liste_triable").each(function() {
            liste=$(this);
            $(this).sortable({
                dropOnEmpty: false,
                axis:liste.attr("data-axis"),
                items: ".triable",
                helper : 'clone',
                connectWith: "[data-connection='"+$(this).attr('data-connection')+"']",
                update : function ()
                {
                    var order = $(this).sortable("serialize");
                    $.post(
                        $(this).attr("data-process"),
                        'prefixe='+$(this).attr("data-item")+'&dest_box='+$(this).attr('data-dest')+'&'+order
                    );
                }
            });
        });
    }

    $('.form-auto .radio-switch').on('switchChange.bootstrapSwitch', function (event, state) {
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
    });

    $('.form-auto .selectpicker').on('change',function(){
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
    });

    $('.form-auto .datepicker').on('change',function(){
        formulaire=$(this).parents('form');
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
    });

    $('.form-auto').on('change, keyup',function(){
        formulaire=$(this);
        submit_form(formulaire.attr('action'),formulaire.attr('id'));
        $(this).find('.linked').each(function() {
            $('.'+$(this).attr('data-title-link')).html(nl2br($(this).val()));
        });
    });

    $('[data-link]').on('click', function(e) {
        e.preventDefault();
        if ($(this).data('target')=="new") {
            var win = window.open($(this).data('link'), '_blank');
              win.focus();
        }
        else { location.href = $(this).data('link'); }

    });
    
    $('[data-title-link]').on('keyup', function(e) {
        e.preventDefault();
        $('.'+$(this).attr('data-title-link')).html($(this).val());

    });

    function submit_form(page_process,nom_form) {
        $.post(page_process,$('#'+nom_form).serialize());
    }

    $('.show-modal').click(function() {
        var route = $(this).attr('data-route');
        var params = $(this).attr('data-params');
        $.ajax({
            type: 'POST',
            processData: true,
            url: '/show-modal/'+route,
            dataType: 'html',
            data:params,
            success: function(data){
                $('#general_modal .modal-content').html(data);
                $('#general_modal').modal('show');
            }
        });
    });


    $('.delete-image').click(function() {
        var imageId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cette image !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/image/delete/','image_id='+imageId, function () {$('#block_'+imageId).hide('slow');});
            }
        });
    });
    
    $('.delete-video').click(function() {
        var videoId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer ce lien !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/video/delete/'+videoId, function () {$('#video_'+videoId).hide('fast');});
            }
        });
    });

    $('.delete-member').click(function() {
        var memberId = $(this).attr('data-member');
        swal({
            title: "Etes-vous sur ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cette fiche !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/member/delete/'+memberId, function () {document.location.href="/";});

            }
        });
    });

    function update(calque,page,params) {
        $.ajax({
            type: 'POST',
            processData: true,
            url: page,
            dataType: 'html',
            data:params,
            success: function(data){
            //alert(data);
            if (calque>'') {$('#'+calque).html(data);}
            }
        });
    }

    $('.changeTab').click(function(){
        $.get('/change-tab/'+$(this).attr('data-name')+'/'+$(this).attr('data-value'));
    });

    $('.delete-link').click(function() {
        var linkId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer ce lien !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/link/delete/'+linkId, function () {$('#link_'+linkId).fadeOut();});
            }
        });
    });
    
    $('.delete-document').click(function() {
        var documentId = $(this).attr('data-id');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer ce document !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/document/delete/'+documentId, function () {$('#document_'+documentId).hide('fast');});
            }
        });
    });

    $('.delete-article').click(function() {
        var articleId = $(this).attr('data-article');
        var refresh = ($(this).attr('data-refresh') =='1');
        swal({
            title: "Êtes-vous sûr ?",
            text: "Cette action est irréversible!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d5241c",
            confirmButtonText: "Oui, supprimer cet article !",
            cancelButtonText: "Annuler",
            closeOnConfirm: true
        }, function(isConfirm)
        {
            if (isConfirm) {
                $.get('/mediadmin/articles/delete/'+articleId, function () {
                    if (refresh) {
                        window.location.href='/mediadmin';
                    } else { $('#article'+articleId).fadeOut();}
                });
            }
        });
    });

    $('.changedStatusBtn').click(function(){
        $('.form-global').each(function() {
            formulaire=$(this);
            submit_form(formulaire.attr('action'),formulaire.attr('id'));
            swal('Enregistrement','Vos modifications ont bien été enregistrées','success');
            $('.changedStatusBtn').removeClass('btn-success');
            $('.changedStatusBtn').addClass('btn-secondary');
        });
        main_form = $(this).parents('.main_form');
        submit_form(main_form.attr('action'),main_form.attr('id'));
        swal('Enregistrement','Vos modifications ont bien été enregistrées','success');
        $('.changedStatusBtn').removeClass('btn-success');
        $('.changedStatusBtn').addClass('btn-secondary');

    });

    $('.btn-legend').on('click',function(){
        var btnId = $(this).attr('data-id');
        $(this).toggleClass('d-none');
        $('#btn-legend-save-'+btnId).toggleClass('d-none');
        $('.collpase-text-'+btnId).toggleClass('d-none');
        $('.collpase-form-text-'+btnId).toggleClass('d-none');
    });

    $('.btn-legend-save').on('click',function(){
        var btnId = $(this).attr('data-id');
        $(this).toggleClass('d-none');
        $('#btn-legend-'+btnId).toggleClass('d-none');
        $('.collpase-text-'+btnId).toggleClass('d-none');
        $('.collpase-form-text-'+btnId).toggleClass('d-none');
    });


    $('.changedStatus input, .changedStatus textarea').on('keydown, change, keypress',function(e){
        $('.changedStatusBtn').removeClass('btn-secondary');
        $('.changedStatusBtn').addClass('btn-success');
        $('.changedStatusBtn').removeAttr('disabled');
    });

    
});