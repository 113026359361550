
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('jquery-ui-bundle');
require('../bootstrap');
require('@coreui/coreui-pro');
require('datatables.net-bs4');
//require('hideseek');
require('../../libs/jquery-sweetalert/sweetalert-dev.js');
require('../../libs/jquery-mask/jquery.mask.js');
require('bootstrap-select');
require('bootstrap-switch');
require('dropzone');
require('../../libs/jquery-cropit/jquery.cropit.js');

global.moment = require('moment');
require('./../../libs/tempusdominus/build/js/tempusdominus-bootstrap-4.min.js');
//require('./../../libs/bootstrap-switch/bootstrap-switch.min.js');


//import 'moment-timezone';
